<template>
  <div class="bodyBox">
    <div v-if="!SmartStorage.get('viewOnly')">
      <iHeader
        backType="blank"
        :headStyle="
          ['msd', 'dsm'].includes($cookies.get('tenant'))
            ? {
                background: '#fff',
                color: '#13161B',
              }
            : {
                background: 'var(--themeColor)',
                color: '#fff',
              }
        "
        :backEvent="true"
        @back-event="closeWebview()"
        :back="true"
      >
        <div slot="headText">
          <span class="head-txt">
            {{
            ["pfizer"].includes($cookies.get("tenant")) ? "租车订单" : "城市车队订单" }}
          </span>
        </div>
       
        <template v-slot:rightBtn v-if="isShowBatchApprovalBtn">
        <div @click="toCityBatchApproval()">批量确认</div>
      </template>
      </iHeader>
    </div>
    <div v-else>
      <div class="opSearchRow">
        <svg class="icon opChooseList2" aria-hidden="true" @click="closeWebview()">
          <use xlink:href="#iconfanhui" />
        </svg>
        <SearchRow @search-call="QueryEOrder(true)" width="1.85rem" placeholder="pr编号、供应商名称" v-model="searchDate"></SearchRow>
      </div>
      <div class="TabBox">
        <li @click="changeTab(btn)" :class="[tabBar.Txt == btn.Txt ? 'selectedTab' : '']" v-for="(btn, tindex) in orderTabs" :key="tindex">
          <div>{{ btn.Txt }}</div>
          <span></span>
        </li>
      </div>
    </div>
    <ScrollBox class="opOrderListScroll" @on-top="scrollTop" @on-bottom="scrollBottom" :loadType="loadType">
      <template v-slot:scrollBox>
        <div v-if="dataList.length" class="contentBox">
          <ul>
            <li v-for="(item, index) in dataList" :key="index" @click="toCarDetail(item)">
              <img v-if="item.SupplierThumbnail" :src="item.SupplierThumbnail" />
              <svg class="icon icon-normal" aria-hidden="true" v-else>
                <use xlink:href="#icon-zhongzhigongyingshangcheduiLOGO" />
              </svg>
              <div class="centerBox">
                <div class="centerBox_top">
                  <p>
                    {{ item.SupplierName || item.SupplierContacts.SupplierName}}
                  </p>
                  <eventTag :statusTxt="item.StatusTxt" :status="item.Status" :classes="'isNoPosition'"></eventTag>
                </div>

                <span class="contactBox" v-for="(contact, contentIndex) in item.SupplierContacts" :key="contentIndex">
                  <a @click.stop="ihtml.telCall(contact.Mobile)" :class="contact.typeContacter == 1 ? 'redfont' : 'blurfont'">{{ contact.Name }}({{ contact.Mobile }})</a>
                </span>
                <!-- <div class="prNum">EasyCarNO:{{item.ExtOrderNum||'暂无'}}</div> -->
                <div class="prNum" v-if="SmartStorage.get('viewOnly')">PR:{{ item.ExtNum }}</div>
                <i>订单创建人:{{ item.Creator_DspName || item.Creator_dspName }}（{{ item.Creator_UserName || item.Creator_userName }}）</i>
                <div class="priceBox">
                  报价:<span class="price mr10">¥{{ Number(item.QuoteAmount).toFixed(2) }}</span>
                  结算:<span class="price">¥{{ Number(item.SettleAmount).toFixed(2) }}</span>
                </div>
                <!-- <div class="currentDriver" v-if="item.Drivers">
                    <div>司机信息:<span>{{ item.Drivers.Name}} ({{item.Drivers.Phone}})</span></div>
                    <div>车辆信息:<span>{{item.Drivers.CarModel}} {{item.Drivers.LicensePlate}}</span></div>
                </div> -->
                <!-- 山德士订单确认/取消icon -->
              <div v-if="['novartis'].includes($cookies.get('tenant'))&&item.ItemData" class="approvalIcon">
                <svg class="icon " aria-hidden="true">
                  <use  :xlink:href="JSON.parse(item.ItemData).OwnerConfirm==1?'#icon-yiqueren':JSON.parse(item.ItemData).OwnerConfirm==2?'#icon-yijujue':''"/>
                </svg>
              </div>
                <div class="processBox">
                  <div v-if="!SmartStorage.get('viewOnly')" @click.stop="goProcess(item)">
                    查看进程
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <Skeleton v-if="isFirst" :skList="skList" />
        
        <NoData v-if="!dataList.length && loadType == '' && !isFirst"></NoData>
      </template>
    </ScrollBox>
    <AddButton v-if="checkCanNewItem.show" :color="$publicFun.setColor()" @click="goItems('')"></AddButton>
  </div>
</template>
<script>
import eventTag from '@/views/common/eventTag';
import moment from "moment";
export default {
  data() {
    return {
      total: 0,
      loadType: '',
      searchDate: '',
      orderTabs: [],
      searchInput: '',
      skList: [
        {
          width: '65%',
          row: 5
        },
        {
          width: '20%',
          position: 'right'
        },
        {
          width: '40%',
          inline: true
        },
        {
          width: '50%'
        },
        {
          width: '30%',
          ml: '.1rem'
        },
        {
          width: '15%',
          inline: true
        },
        {
          width: '100%',
          inline: true
        },
        {
          width: '0',
          inline: true
        }
      ],
      isFirst: true,
      checkCanNewItem: {},
      topHeight: !this.SmartStorage.get('viewOnly') ? 80 : 120,
      pageIndex: 1,
      pageSize: 10,
      dataList: [],
      tabBar: {},
      isTwoDaysLater:false,
    };
  },
  components: {
    eventTag
  },
  mounted() {
    console.log(document.getElementsByClassName('opOrderListScroll'));
    document.getElementsByClassName('opOrderListScroll')[0].style['margin-top'] = this.SmartStorage.get('viewOnly') ? '0' : '.6rem';
  },
  computed:{
    isShowBatchApprovalBtn(){
      return ['novartis'].includes(this.$cookies.get('tenant'))&&this.SmartStorage.get('eventData').extData.isLargeMeeting&&this.SmartStorage.get('eventData').userId==this.$cookies.get('userId')&&this.isTwoDaysLater;
    }
  },
  async created() {
    if(['novartis'].includes(this.$cookies.get('tenant'))){
    // 山德士的批量审批按钮显示需求
    let data1=moment(this.SmartStorage.get('eventData').dtEnd);//会议结束日期
    let date2=moment();//当前日期
    let day = date2.diff(data1, 'day');
    this.isTwoDaysLater=day>=1;
    console.log(' this.isTwoDaysLater',this.isTwoDaysLater);
    }
  
    this.redirectRouteListeners(); //记录全局路由轨迹
    this.$root.$eventHub.$on('closeWebview', data => {
      this.closeWebview();
    });
    let tabBar = this.SmartStorage.get('orderlistStatu');
    if (tabBar) {
      this.tabBar = tabBar;
    }
    !this.SmartStorage.get('viewOnly') && this.checkItem();
    this.StatByStatus();
  },
  methods: {
    scrollTop(filter) {
      this.loadType = 'top';
      this.pageIndex = 1;
      if (filter) {
        this.dataList = [];
      }
      this.QueryEOrder(true);
    },
    scrollBottom() {
      if (this.pageSize * this.pageIndex >= this.total) {
        this.loadType = 'none';
      } else {
        this.loadType = 'bottom';
        this.pageIndex += 1;
        this.QueryEOrder();
      }
    },
    openIhelper() {
      let appData = {
        webappAlias: {
          urlKey: 'webapp-ihelper',
          type: 'insideWebsite',
          path: '',
          params: 'type=customServer'
        },
        callback: this.viewBack
      };
      this.$publicFun.openWebApplication(appData);
    },
    changeTab(btn) {
      this.tabBar = btn;
      this.QueryEOrder(true);
    },
    StatByStatus() {
      let params = {
        Channel: 2
      };
      if (this.SmartStorage.get('proposalId')) {
        params.ProposalId = this.SmartStorage.get('proposalId');
      }
      let _this = this;
      this.services.StatByStatus(params).then(function(res) {
        if (res.success) {
          _this.tabBar = _this.tabBar.Txt ? _this.tabBar : res.content[0];
          _this.orderTabs = res.content;
          console.log(_this.tabBar);
          _this.QueryEOrder();
        }
      });
    },
    goProcess(data) {
      this.$router.push({
        path: '/cityProjectProcess',
        query: {
          orderNum: data.OrderNum
        }
      });
    },
    redirectRouteListeners() {
      this.SmartStorage.set('routeListeners', ['closeWebview']);
    },
    // 退出城市车队页面
    closeWebview() {
      const container = this.$cookies.get('container');
      switch (container) {
        case 'browser':
          this._browserBack();
          break;
        case 'h5':
          this._h5Back();
          break;
        case 'native':
        default:
          this.closeAppWithReload();
      }
    },
    toCarDetail(data) {
      if (data) {
        data.orderId = data.OrderId;
        this.SmartStorage.set('carOrder', data);
      }
      let type = data.Status > 50 || data.Status == -2 ? '/carAccount' : '/carDetail';
      this.$router.push(type);
    },
    goItems(data) {
      if (this.checkCanNewItem.forbidden) {
        this.checkCanNewItem.tooltip ? this.$iToast(this.checkCanNewItem.tooltip) : this.$iToast('暂时不能添加');
      } else {
        this.$router.push('/items');
      }
    },
    //是否可以添加需求
    async checkItem() {
      let param = {
        proposalId: this.SmartStorage.get('proposalId'),
        isOnline: false,
        sourceCode: ''
      };
      let _this = this;
      this.services
        .CheckCanNewItem(param)
        .then(function(res) {
          _this.checkCanNewItem = res.content;
          _this.SmartStorage.set('checkCanNewItem', _this.checkCanNewItem);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    async QueryEOrder(clear) {
      let params = {
        Channel: 2,
        pagerIndex: this.pageIndex,
        pagerSize: this.pageSize,
        Filter: {},
        Projection: {
          _id: 0,
          CreatedOn: 1,
          TenantId: 1,
          OrderId: 1,
          EventId: 1,
          OrderNum: 1,
          UserId: 1,
          Status: 1,
          StatusTxt: 1,
          SupplierId: 1,
          SupplierName: 1,
          ExtNum: 1,
          Creator_DspName: 1,
          Creator_UserName: 1,
          ExtData: 1,
          IsDeleted: 1,
          SupplierThumbnail: 1,
          QuoteAmount: 1,
          SettleAmount: 1,
          SupplierContacts: 1,
          Drivers:1
        },
        Sort: { CreatedOn: -1 }
      };
      if (this.tabBar.Txt != '全部') {
        params.Filter.Status = this.tabBar?.CountExpr && JSON.parse(this.tabBar.CountExpr).Status;
      }
      params.isAuthorized = this.$cookies.get('tenant') == 'msd' || this.SmartStorage.get('eventData').isFromMore ? true : false;
      if (this.SmartStorage.get('proposalId')) {
        params.Filter.EventId = this.SmartStorage.get('proposalId');
      }
       //只有山德士需要批量确认用到taskId
       if (this.SmartStorage.get('tenant_code') == 'novartis') {
        params.withTaskId = true;
      }
      if (this.searchDate) {
        params.Filter.$or = [
          {
            SupplierName: {
              $regex: this.searchDate
            }
          },
          {
            ExtNum: { $regex: this.searchDate }
          }
        ];
      }
      let _this = this;
      this.services.Paging(params).then(function(res) {
        _this.isFirst = false;
        if (res.success) {
          if (clear == true) {
            _this.dataList = [];
          }

          let eventList = res.content.rows;
          for (let i = 0; i < eventList.length; i++) {
            _this.dataList.push(eventList[i]);
          }
          _this.total = res.content.total;
          _this.loadType = '';
          console.log('_this.dataList', _this.dataList)
        }
      });
    },
    toCityBatchApproval(){
      this.$router.push({
        name: 'cityBatchApproval',
      })
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/tenant/index.scss';
.approvalIcon{
  position: absolute;
  right: .1rem;
  bottom: .8rem;
  .icon{
    width: .4rem;
    height: .4rem;
  }
}
</style>
